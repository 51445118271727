import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/Login';
import BioData from './components/BioData'; // Import your Biodata component

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/biodata" element={<BioData />} />
      </Routes>
    </Router>
  );
}

export default App;
