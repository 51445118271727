import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Make sure to create this CSS file

const LoginPage = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    let navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`/api/verify-mobile`, { // Notice the relative URL here
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobile_number: phoneNumber }),
            });
            if (response.ok) {
                navigate('/biodata'); // This will navigate to the Biodata page
            } else {
                alert('Invalid phone number'); // Simple alert for invalid number
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
    };


    return (
        <div className="login-container">
            {/* ... */}
            <form onSubmit={handleSubmit} className="login-box">
                <div className="input-group">
                    <input
                        type="text"
                        placeholder="Enter 12345"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="submit-button">Submit</button>
            </form>
        </div>
    );
};

export default LoginPage;
