import React, { useEffect, useState } from 'react';
// import myPic from './my_pic.jpg'
import myPic1 from './my_pic_3.JPG'
import myPic2 from './my_pic_4.JPG'
import './BioData.css';

const Biodata = () => {
    const [biodata, setBiodata] = useState(null);

    useEffect(() => {
        fetch(`/api/biodata`)  // Adjust this if your endpoint differs
            .then(response => response.json())
            .then(data => setBiodata(data));
    }, []);

    if (!biodata) {
        return <div className="biodata-container">Loading...</div>;
    }

    return (
        <div className="bio-data">
            <h1 className='biodata-header'>Biodata</h1>
        <div className="biodata-container">
            <div className="biodata-photo">
                <div className="biodata-photo1">
                    <img src={myPic1} alt="Biodata1" />
                </div>
                <div className="biodata-photo2">
                    <img src={myPic2} alt="Biodata2" />
                </div>
            </div>
            <div className="biodata-info">
            <p><strong>Name:</strong> {biodata.name}</p>
            <p><strong>Date of Birth:</strong> {biodata.dob}</p>
            <p><strong>Time of Birth:</strong> {biodata.tob}</p>
            <p><strong>Place of Birth:</strong> {biodata.pob}</p>
            <p><strong>Rashi:</strong> {biodata.rashi}</p>
            <p><strong>Nakshatram (star):</strong> {biodata.star}</p>
            <p><strong>Gothram:</strong> {biodata.gothram}</p>
            <p><strong>Complexion:</strong> {biodata.complexion}</p>
            <p><strong>Caste:</strong> {biodata.caste}</p>
            <p><strong>Religion:</strong> {biodata.religion}</p>
            <p><strong>Education:</strong> {biodata.education}</p>
            <p><strong>Graduation Year:</strong> {biodata.graduation_year}</p>
            <p><strong>Designation:</strong> {biodata.designation}</p>
            <p><strong>Company:</strong> {biodata.company}</p>
            <p><strong>Salary:</strong> {biodata.salary}</p>
            <p><strong>Blood Group:</strong> {biodata.blood_group}</p>
            <p><strong>Height:</strong> {biodata.height}</p>
                {/* <p><strong>Weight:</strong> {biodata.weight}</p> */}
                <p><strong>Linkedin:</strong> <a href={biodata.linkedin} target="_blank" rel="noopener noreferrer"> {biodata.linkedin} </a> </p>
                <div className="family-background">
                    <h3>Family Background</h3>
                    <p><strong>Father:</strong> {biodata.father}</p>
                    <p><strong>Mother:</strong> {biodata.mother}</p>
                    <p><strong>Sister:</strong> {biodata.sister} <hr/> <strong>Mother's Contact:</strong> {biodata.mother_contact}</p>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Biodata;
